import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import useIntersectionAnimation from '@modules/hooks/useIntersectionAnimation';
import { IsMobileSize } from '@modules/state/common';
import { User } from '@modules/state/user';
import { Mobile, PC } from '@components/MediaQuery';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';
import ArrowForward from '@images/home/arrow-forward.svg';

const CustomVoiceSection = () => {
    const { t, locale } = useTranslation('home');

    const router = useRouter();

    const isMobile = useRecoilValue(IsMobileSize);
    const user = useRecoilValue(User);

    const setRef = useIntersectionAnimation({
        threshold: 0.4,
        styleClass: styles.pop,
    });

    const handleDetailsClick = () => {
        if (user.accessToken) {
            router.push('/dashboard/custom');
        } else {
            router.push('/login');
        }
    };

    return (
        <section className={`${styles.section} ${styles.custom_voice}`}>
            <div className={styles.inner}>
                <Title
                    label="Custom AI Voice"
                    title={t('customVoiceSection.title')}
                    subtitle={!isMobile ? t('customVoiceSection.subtitle') : t('customVoiceSection.subtitleMobile')}
                />

                <div className={styles.steps}>
                    <div ref={setRef} className={styles.step}>
                        <p className={styles.title}>{t('customVoiceSection.step1')}</p>
                        <img
                            src={`/images/home/customvoice/step1${locale !== 'ko' ? '_en' : ''}.png`}
                            alt={t('customVoiceSection.step1')}
                        />
                    </div>

                    <div ref={setRef} className={styles.step}>
                        <img
                            src={`/images/home/customvoice/step2${locale !== 'ko' ? '_en' : ''}.png`}
                            alt={t('customVoiceSection.step2')}
                        />
                        <p className={styles.title}>{t('customVoiceSection.step2')}</p>
                    </div>

                    <div ref={setRef} className={styles.step}>
                        <div className={styles.text_area}>
                            <p className={styles.title}>{t('customVoiceSection.step3')}</p>

                            <Mobile>
                                <img
                                    src={`/images/home/customvoice/step3${locale !== 'ko' ? '_en' : ''}.png`}
                                    alt={t('customVoiceSection.step3')}
                                />
                            </Mobile>

                            <button type="button" onClick={handleDetailsClick} className={styles.cta_btn}>
                                {t('customVoiceSection.more')}
                                <PC>
                                    <ArrowForward width={20} height={19} fill="white" />
                                </PC>
                            </button>

                            <span className={styles.label}>{t('customVoiceSection.step3Heading')}</span>
                            <ul>
                                {t('customVoiceSection.step3Bullets').map((item: string, index: number) => (
                                    <li key={index}>
                                        <b>✓&nbsp;</b>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <PC>
                            <img
                                src={`/images/home/customvoice/step3${locale !== 'ko' ? '_en' : ''}.png`}
                                alt={t('customVoiceSection.step3')}
                            />
                        </PC>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomVoiceSection;
