import { useEffect, useRef } from 'react';

interface UseIntersectionAnimationOptions {
    threshold?: number;
    styleClass: string;
}

export default function useIntersectionAnimation({ threshold = 0.1, styleClass }: UseIntersectionAnimationOptions) {
    const elementsRef = useRef<HTMLDivElement[]>([]);

    // ref 콜백 함수: 해당 요소를 배열에 추가합니다.
    const addToRefs = (el: HTMLDivElement | null) => {
        if (el && !elementsRef.current.includes(el)) {
            elementsRef.current.push(el);
        }
    };

    useEffect(() => {
        if (!elementsRef.current.length) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const target = entry.target as HTMLElement;
                        target.classList.add(styleClass);
                    }
                });
            },
            { threshold },
        );

        elementsRef.current.forEach((el) => observer.observe(el));

        // eslint-disable-next-line consistent-return
        return () => observer.disconnect();
    }, [threshold, styleClass]);

    return addToRefs;
}
