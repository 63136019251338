import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import useTranslation from '@modules/hooks/useTranslation';
import useIntersectionAnimation from '@modules/hooks/useIntersectionAnimation';
import { User } from '@modules/state/user';
import { Mobile, PC } from '@components/MediaQuery';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';
import ArrowForward from '@images/home/arrow-forward.svg';

const FeatureSection = () => {
    const router = useRouter();

    const { t, locale } = useTranslation('home');

    const setRef = useIntersectionAnimation({
        threshold: 0.3,
        styleClass: styles.pop,
    });

    const user = useRecoilValue(User);
    const handleCTAClick = async (type: 'STS' | 'TTS') => {
        if (user.accessToken) {
            await router.push(type === 'STS' ? '/studio/speech-to-speech' : '/studio/text-to-speech');
            return;
        }
        await router.push('/login');
    };

    return (
        <section ref={setRef} className={`${styles.section} ${styles.feature}`}>
            <div className={styles.inner}>
                <Title label={t('featureSection.label')} title={t('featureSection.title')} />

                <div className={styles.feature_list}>
                    <div ref={setRef} className={styles.feature_item}>
                        <img src={`/images/home/feature/TTS${locale !== 'ko' ? '_en' : ''}.png`} alt="TTS" />
                        <div className={styles.text_area}>
                            <p className={styles.title}>{t('featureSection.TTS.title')}</p>
                            <span className={styles.text}>{t('featureSection.TTS.text')}</span>

                            <PC>
                                <button type="button" onClick={() => handleCTAClick('TTS')} className={styles.cta_btn}>
                                    {t('featureSection.experiencing', { feature: 'TTS' })}
                                    <ArrowForward width={20} height={19} />
                                </button>
                            </PC>

                            <ul className={styles.ai_feature_list}>
                                {t('featureSection.TTS.bullets').map((item: string, index: number) => (
                                    <li key={index}>
                                        <b>✓ </b>
                                        {item}
                                    </li>
                                ))}
                            </ul>

                            <Mobile>
                                <button type="button" onClick={() => handleCTAClick('TTS')} className={styles.cta_btn}>
                                    {t('featureSection.experiencing', { feature: 'TTS' })}
                                    <ArrowForward width={20} height={19} />
                                </button>
                            </Mobile>
                        </div>
                    </div>
                    <div ref={setRef} className={styles.feature_item}>
                        <div className={styles.text_area}>
                            <p className={styles.title}>{t('featureSection.STS.title')}</p>
                            <span className={styles.text}>{t('featureSection.STS.text')}</span>

                            <PC>
                                <button type="button" onClick={() => handleCTAClick('STS')} className={styles.cta_btn}>
                                    {t('featureSection.experiencing', { feature: 'STS' })}
                                    <ArrowForward width={20} height={19} />
                                </button>
                            </PC>

                            <ul className={styles.ai_feature_list}>
                                {t('featureSection.STS.bullets').map((item: string, index: number) => (
                                    <li key={index}>
                                        <b>✓ </b>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                            <Mobile>
                                <button type="button" onClick={() => handleCTAClick('STS')} className={styles.cta_btn}>
                                    {t('featureSection.experiencing', { feature: 'STS' })}
                                    <ArrowForward width={20} height={19} />
                                </button>
                            </Mobile>
                        </div>

                        <img src={`/images/home/feature/STS${locale !== 'ko' ? '_en' : ''}.png`} alt="STS" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureSection;
