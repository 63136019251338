import { useLayoutEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import useScrollStop from '@modules/hooks/useScrollStop';
import { getModalClose } from '@modules/utils/cookie';
import { IsNoticeModalOpen } from '@modules/state/home';
import { PC } from '@components/MediaQuery';
import LadingSection from '@feature/home/LandingSection';
import CustomVoiceSection from '@feature/home/CustomVoiceSection';
import FeatureSection from '@feature/home/FeatureSection';
import UsageSection from '@feature/home/UsageSection';
import PlanSection from '@feature/home/PlanSection';
import PromotionSection from '@feature/home/PromotionSection';
import Scalability from '@feature/home/Scalability';
import EndingSection from '@feature/home/EndingSection';
import styles from '@styles/Home.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Home = () => {
    const { locale } = useRouter();

    const Notice = dynamic(() => import('@components/modal/Notice'));

    const [isNoticeModalOpen, setIsNoticeModalOpen] = useRecoilState(IsNoticeModalOpen);

    useLayoutEffect(() => {
        setIsNoticeModalOpen(!(getModalClose('UNM') === 'false'));
    }, []);

    const [showContactBtn, setShowContactBtn] = useState(true);

    const isScrollStopped = useScrollStop(1000);

    return (
        <>
            {isNoticeModalOpen && <Notice />}

            <div className={styles.wrap}>
                <LadingSection setIsVisible={setShowContactBtn} />
                <CustomVoiceSection />
                <FeatureSection />
                <UsageSection />
                <PlanSection />
                <PromotionSection />
                <Scalability />
                <EndingSection setIsVisible={setShowContactBtn} />
            </div>

            <button
                type="button"
                name="contact"
                title="궁금한 점이 있나요?"
                data-tally-open={locale === 'ko' ? 'wkl8jM' : 'mYk1A5'}
                data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave"
                className={`${styles.contact_btn} ${showContactBtn || isScrollStopped ? styles.visible : ''}`}
            >
                <img src="/images/home/contact.png" alt="contact" width={32} height={32} />
                <PC>{locale === 'ko' ? '궁금한 점이 있나요?' : 'Got any question?'}</PC>
            </button>
        </>
    );
};

export default Home;
