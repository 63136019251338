import { useEffect, useState } from 'react';

export default function useScrollStop(delay = 200) {
    const [isScrollStopped, setIsScrollStopped] = useState(true);

    useEffect(() => {
        let timer: any;

        const handleScroll = () => {
            setIsScrollStopped(false);

            clearTimeout(timer);
            timer = setTimeout(() => {
                setIsScrollStopped(true);
            }, delay);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [delay]);

    return isScrollStopped;
}
