import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useTranslation from '@modules/hooks/useTranslation';
import { Mobile, PC } from '@components/MediaQuery';
import { RPlanList } from '@modules/state/plan';
import styles from '@styles/Home.module.scss';

const PromotionSection = () => {
    const { t } = useTranslation('home');

    const planList = useRecoilValue(RPlanList);
    const [countryCode, setCountryCode] = useState('');
    const [price, setPrice] = useState(0);

    useEffect(() => {
        if (planList.length === 0) return;

        const localCountryCode = window.localStorage.getItem('countryCode');
        if (localCountryCode && localCountryCode !== '') {
            setCountryCode(localCountryCode);
            if (localCountryCode === 'KR') {
                setPrice(planList.find((item) => item.name === 'Creator')?.price || 0);
            } else {
                setPrice(planList.find((item) => item.name === 'Creator')?.priceEn || 0);
            }
        }
    }, [planList]);

    return (
        <section className={`${styles.section} ${styles.promotion}`}>
            <div className={styles.inner}>
                <PC>
                    <h4 className={styles.title}>{t('promotionSection.title')}</h4>
                </PC>
                <Mobile>
                    <h4 className={styles.title}>{t('promotionSection.titleMobile')}</h4>
                </Mobile>

                <div className={styles.price_area}>
                    <div className={styles.discount}>
                        <del>{price.toLocaleString('ko-KR')}</del>
                        <div className={styles.rate}>-50%</div>
                    </div>
                    <div className={styles.plan_price}>
                        <span className={styles.plan_name}>Creator</span>
                        <span className={styles.price}>
                            {countryCode !== 'KR' && '$'}
                            <b>{Number((price / 2).toFixed(2)).toLocaleString('ko-KR')}</b>
                            {countryCode === 'KR' && t('promotionSection.period')}
                        </span>
                    </div>
                </div>
                <PC>
                    <span className={styles.guide}>{t('promotionSection.guide')}</span>
                </PC>
                <Mobile>
                    <span className={styles.guide}>{t('promotionSection.guideMobile')}</span>
                </Mobile>
            </div>
        </section>
    );
};

export default PromotionSection;
