import React from 'react';
import useTranslation from '@modules/hooks/useTranslation';
import useIntersectionAnimation from '@modules/hooks/useIntersectionAnimation';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';

interface contentsListType {
    imgURL: string;
    title: string;
}

const Scalability = () => {
    const { t } = useTranslation('home');
    const text = t('scalabilitySection');

    const setRef = useIntersectionAnimation({
        threshold: 0.4,
        styleClass: styles.pop,
    });

    return (
        <section className={`${styles.section} ${styles.scalability}`}>
            <div ref={setRef} className={styles.inner}>
                <Title title={text.title} />

                <ul className={styles.contents_list}>
                    {text.contentsList.map((item: contentsListType, index: number) => (
                        <li key={index} className={styles.contents_item}>
                            <img src={item.imgURL} alt="" loading="lazy" />
                            <span className={styles.title}>{item.title}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Scalability;
