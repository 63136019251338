import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import { User } from '@modules/state/user';
import { PC } from '@components/MediaQuery';
import styles from '@styles/Home.module.scss';
import ArrowForward from '@images/home/arrow-forward.svg';

const Video = ({ language }: { language: 'ko' | 'en' }) => {
    const { t } = useTranslation('home');

    const [isVideoMuted, setIsVideoMuted] = useState(true);

    return (
        <div className={styles.video_area}>
            <video autoPlay loop muted={isVideoMuted} playsInline className={styles.video}>
                <source
                    src={`${process.env.NEXT_PUBLIC_AWS_URL}/landing/VOLI_Official_${language}.webm`}
                    type="video/webm"
                />
                <source
                    src={`${process.env.NEXT_PUBLIC_AWS_URL}/landing/VOLI_Official_${language}.mp4`}
                    type="video/mp4"
                />
            </video>
            <button
                type="button"
                name={isVideoMuted ? 'muted' : ''}
                onClick={() => setIsVideoMuted(!isVideoMuted)}
                className={styles.control_btn}
            >
                {isVideoMuted ? t('playSound') : 'Off'}
            </button>
        </div>
    );
};

interface Props {
    setIsVisible: (isVisible: boolean) => void;
}
const LandingSection = ({ setIsVisible }: Props) => {
    const { t } = useTranslation('home');

    const router = useRouter();
    const { locale } = useRouter();

    const user = useRecoilValue(User);

    const handleStartClick = () => {
        if (user.accessToken) {
            router.push('/studio/text-to-speech');
        } else {
            router.push('/login');
        }
    };

    const elementRef = useRef<HTMLDivElement>();
    useEffect(() => {
        if (!elementRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting === undefined) return;

                    setIsVisible(entry.isIntersecting);
                });
            },
            { threshold: 0.1 },
        );

        observer.observe(elementRef.current!);

        // eslint-disable-next-line consistent-return
        return () => observer.disconnect();
    }, []);

    return (
        <section ref={(element: HTMLDivElement) => (elementRef.current = element)} className={styles.landing}>
            <div className={styles.inner}>
                <div className={styles.text_area}>
                    <h2>{t('title')}</h2>
                    <h3>{t('text')}</h3>

                    <div className={styles.btn_area}>
                        <button
                            type="button"
                            aria-label={t('CTA')}
                            onClick={handleStartClick}
                            className={styles.start_btn}
                        >
                            {t('CTA')}
                            <PC>
                                <ArrowForward width={20} height={19} />
                            </PC>
                        </button>
                        <a
                            href={locale === 'ko' ? 'https://tally.so/r/mJMD8o' : 'https://tally.so/r/w8MX4O'}
                            target="_blank"
                            className={styles.business_link}
                            rel="noreferrer"
                        >
                            {t('businessInquiry')}
                        </a>
                    </div>
                </div>

                {locale === 'ko' && <Video language="ko" />}
                {locale !== 'ko' && <Video language="en" />}
            </div>
        </section>
    );
};

export default LandingSection;
