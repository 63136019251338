import useTranslation from '@modules/hooks/useTranslation';
import styles from '@styles/Home.module.scss';
import { useEffect, useRef } from 'react';
import VOLI from '../../../public/VOLI-white.svg';

interface Props {
    setIsVisible: (isVisible: boolean) => void;
}

const EndingSection = ({ setIsVisible }: Props) => {
    const { t } = useTranslation('home');

    const elementRef = useRef<HTMLDivElement>();
    useEffect(() => {
        if (!elementRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsVisible(entry.isIntersecting);
                });
            },
            { threshold: 0.5 },
        );

        observer.observe(elementRef.current!);

        // eslint-disable-next-line consistent-return
        return () => observer.disconnect();
    }, []);

    return (
        <section ref={(element: HTMLDivElement) => (elementRef.current = element)} className={styles.ending}>
            <span className={styles.title}>{t('endingSection.title')}</span>
            <VOLI width={162} />
            <div className={styles.btn_area}>
                <button type="button">{t('endingSection.start')}</button>
                <a href="https://tally.so/r/mJMD8o" target="_blank" className={styles.business_link} rel="noreferrer">
                    {t('endingSection.contact')}
                </a>
            </div>
        </section>
    );
};

export default EndingSection;
