import styles from '@styles/Home.module.scss';

interface Props {
    label?: string;
    title: string;
    subtitle?: string;
}

const Title = ({ label, title, subtitle }: Props) => {
    return (
        <div className={styles.title_area}>
            {label && <h5 className={styles.label}>{label}</h5>}
            <h3 className={styles.title}>{title}</h3>
            {subtitle && <h6 className={styles.subtitle}>{subtitle}</h6>}
        </div>
    );
};

export default Title;
