import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Link from 'next/link';
import useTranslation from '@modules/hooks/useTranslation';
import useIntersectionAnimation from '@modules/hooks/useIntersectionAnimation';
import { IsMobileSize } from '@modules/state/common';
import { RPlanList } from '@modules/state/plan';
import { PC } from '@components/MediaQuery';
import Title from '@feature/home/components/Title';
import styles from '@styles/Home.module.scss';
import Crown from '@assets/plan/crown.svg';
import ArrowForward from '@images/home/arrow-forward.svg';

const PlanSection = () => {
    const { t } = useTranslation('home');

    const isMobile = useRecoilValue(IsMobileSize);

    const setRef = useIntersectionAnimation({
        threshold: !isMobile ? 0.4 : 0.1,
        styleClass: styles.pop,
    });

    const planList = useRecoilValue(RPlanList);
    const [countryCode, setCountryCode] = useState('');

    const translateRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        const countryCode = window.localStorage.getItem('countryCode');
        if (countryCode !== null) {
            setCountryCode(countryCode);
        }
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const element = translateRef?.current;
        if (!element) return;

        const container = element.parentElement;
        if (!container) return;

        const onScroll = () => {
            const rect = container.getBoundingClientRect();

            let ratio = (window.innerHeight * 0.8 - rect.top) / (window.innerHeight * 0.7);
            ratio = Math.min(Math.max(ratio, 0), 1);

            const width = element.offsetWidth + (window.innerWidth + 40) / 2;

            const pxValue = -width * ratio;
            const vwValue = 100 * ratio;

            element.style.transform = `translate3d(calc(${pxValue}px + ${vwValue}vw), 0px, 0px)`;
        };

        if (!isMobile) {
            // eslint-disable-next-line consistent-return
            return () => window.removeEventListener('scroll', onScroll);
        }

        // let ticking = false;
        // window.addEventListener('scroll', () => {
        //     if (!ticking) {
        //         window.requestAnimationFrame(() => {
        //             onScroll();
        //             ticking = false;
        //         });
        //         ticking = true;
        //     }
        // });
        window.addEventListener('scroll', onScroll);
        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener('scroll', onScroll);
    }, [isMobile, translateRef]);

    return (
        <section ref={setRef} className={`${styles.section} ${styles.plan}`}>
            <div className={styles.inner}>
                <Title title={t('planSection.title')} />

                <div className={styles.plan_area}>
                    <ul ref={translateRef} className={styles.plan_list}>
                        {planList.slice(0, 4).map((item, index) => (
                            <li
                                key={item.id}
                                className={`${styles.plan_item} ${
                                    item.name.toLowerCase() === 'creator' ? styles.highlight : ''
                                }`}
                            >
                                <span className={styles.name}>{item.name}</span>

                                <PC>
                                    {item.name.toLowerCase() === 'creator' && <Crown className={styles.recommend} />}
                                </PC>

                                <span className={styles.text}>{item.description}</span>
                                <div className={styles.price}>
                                    <strong>
                                        {countryCode !== 'KR' && '$'}
                                        {item.price === 0
                                            ? t('planSection.free')
                                            : countryCode === 'KR'
                                            ? item.price.toLocaleString('ko-KR')
                                            : item.priceEn}
                                    </strong>
                                    {countryCode === 'KR' && (
                                        <span>{index === 0 ? t('planSection.period') : t('planSection.periodKo')}</span>
                                    )}
                                </div>
                                <hr />
                                <span className={styles.target}>{item.features[0]}</span>
                                <ul className={styles.feature_list}>
                                    {item.features.slice(1).map((item, index) => (
                                        <li key={index} className={styles.feature_item}>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: item,
                                                }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <div className={styles.banner}>
                        <span>{t('planSection.banner')}</span>
                        <Link href="/plan">
                            <a>
                                {t('planSection.cta')}
                                <PC>
                                    <ArrowForward width={20} height={19} fill="#2F3237" />
                                </PC>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PlanSection;
